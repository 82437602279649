<template>
  <div v-if="project" class="row">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">{{ project.title }} Documents:</h4>

          <div class="form-group">
            <l-button class="mr-lg-1" @click="$router.go(-1)" type="danger">
               <span style="margin-right: 3px" class="btn-label">
                    <i class="fa fa-chevron-left"></i>
             </span>
              Back
            </l-button>
          </div>
        </div>


        <div class="col-sm-12">
          <el-table stripe
                    ref="multipleTable"
                    style="width: 100%;"
                    :data="tableData"
                    border>

            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             align="center"
                             :prop="column.prop"
                             :label="column.label"
                             :formatter="column.formatter !== null ? column.formatter : null">

            </el-table-column>

            <el-table-column
              :min-width="150"
              align="center"
              label="Actions">
              <template slot-scope="props">
                <a href="javascript:void(0);" @click="download(props.row.file_name,props.row.file_path)">
                  Download
                </a>
              </template>
            </el-table-column>

          </el-table>
        </div>

        <div slot="footer">

        </div>

        <!--  End table -->

      </card>


    </div>

  </div>
</template>
<script>
import {Table, TableColumn, Select, Option, Tag, DatePicker, Popconfirm, Popover, Button} from 'element-ui'
import {NewPagination as NLPagination} from 'src/components/index'
import DeleteAllModal from "@/components/DeleteAllModal";

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },

  data() {

    return {
      loader: {},
      tableColumns: [
        {
          prop: 'document_description',
          label: 'Document Title',
          minWidth: 100
        }
      ],

      tableData: [],
      project: null,
    }
  },

  async created() {

    try {
      let response = await this.axios.get("projects/" + this.$route.params['uuid'] + "/get-project");
      this.project = response.data;
    } catch (e) {
      if (e.response !== undefined && e.response.status === 404) {
        this.$notify({
          message: "Project Not Found",
          timeout: 2000,
          type: 'danger'
        });
      }
    }

    if (this.project) {
      this.getData();
    }

  },

  methods: {

    getData() {
      this.loader = this.$loading.show({
        container: this.$refs.multipleTable
      });
      this.axios.get("projects/" + this.$route.params['uuid'] + "/project-documents").then((response) => {
        this.tableData = response.data;
        this.loader.hide();
      }).catch((error) => {
        console.error(error);
      });
    },

    download(fileName, filePath) {
      this.axios.post("projects/" + this.$route.params['uuid'] + "/download-document",
        {"path": filePath},
        {responseType: 'blob'})
        .then(response => {
          let blob = new Blob([response.data], {type: response.headers['content-type']})
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click()
        })
    }

  },


}
</script>
<style>
</style>
